@import "_global";

html{
  overflow-y: auto;
}

body{
  font-family: $font-body;
  padding: 0;
  margin: 0;
}

h1,h2,h3,h4,h5,h6, .title, .subtitle, .card-header-title, .tag{
  font-family: $font-title;
}

table{
  user-select:all;
}

.tag {
  
}

.RedMachine{
  margin:0;
  padding: 0;
  
  & > .page >  nav {
    position: sticky;
    top: 0;
    background-color: white;
    height: $nav-height;
    min-height: $nav-height;
    /*z-index: 1000;*/
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

    
    img.navbar-logo {
      height: $nav-height - 20px;
      margin: 10px; 
      }

    a.menu-interface-link{
      
      }

    a.menu-interface-link::before{
      /*content:'➤';*/
      }

    .menu-separator-above{
      border-top: 1px solid $light;
      margin-top: 10px;
      padding-top: 10px;
    }

    .buttons .button {
      margin-top: 0.25rem;
    }



  }

  & > footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: $footer-height;
    background-color: #aaa;
    color: white;
    font-size: $footer-height;
    line-height: $footer-height;
    text-align: right;
  }
}




.button-icon-delete {
  cursor:pointer;
}




/*login stuff */

.modal-card{
  webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5); 
  box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
}

.interfaceContainer{
  position: relative;
}


#grapharea{
  position: absolute;
  width: 100%;
  height: calc(100vh - $nav-height);
  overflow-y: auto;

  &.with-sidebar{
    width: calc(100% - $sidebar-width);
  }

  .left, .right, .top, .bottom{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: auto; }

  .left{
    left: 0;
    width: 50%; }

  .right{
    right: 0;
    width: 50%; }

  .top{
    top: 0;
    height: 50%;}

  .bottom{
    bottom: 0;
    height: 50%;}
}

#grapharea-container.with-sidebar #grapharea{
  width: calc(100% - $sidebar-width);
}



.side-bar {
  width: $sidebar-width;
  height: calc(100vh - $nav-height);
  right: 0;
  position: absolute;
  overflow: auto;
  padding: 5px;
}


.rm-codeoverlay-master, .rm-pluginfactory-master{
  position: absolute;
  top: $nav-height;
  width: 100%;
  z-index: 5;
}

.rm-project-editor {
  .modal-card {
    max-height: calc(100% - 3* $nav-height);
  }
}



.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: backdrop-filter 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    backdrop-filter: grayscale(0%) contrast(100%);
    z-index: 999;
    
    .loader {
      display:none;
      height: 140px;
      width: 140px;
    }



    &.is-active {
        opacity: 1;
        backdrop-filter: grayscale(60%) contrast(90%);
        pointer-events: auto;
        z-index:999;

        .loader{
          display:block;
        }
    }
}

.is-dual-left {
  width:50%;
  left:0%
}

.is-dual-right {
  width:50%;
  left:50%
}

.hero *{
  text-shadow: 3px 3px 20px #ffffff,
    -2px 1px 30px #ffffff;
}


.plugin-factory nav.navbar{
  filter: invert(1);
}

.nested.dropdown {
  &:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-menu {
    top: -15px;
    margin-left: 100%;
  }
  .dropdown-trigger {
    button {
      padding: 0px 0px;
      border: 0px;
      font-size: 14px;
      font-weight: 400;
      height: 2em;
    }
  }
}



@media (max-width: $desktop) {
  #tsparticles {
    display:none; //hide particle display on mobile
  }

  #grapharea{
    height: calc(100vh);
  }
}

.selection-helper span{
  cursor:pointer;
}

.server-error-message{
  position:absolute;
  top: 50%;
  left: 50%;
  transform:translateY(-50%) translateX(-50%);
  min-width: 300px;
}

.file-item-menu{
  min-width: 300px;
}


/**** WIKI ***/

.wiki{
  z-index: 5;
}

input#wiki-address {
  min-width: 400px;
}

.wiki-content {
  all: revert;
  max-height: 500px;
  overflow: auto;

  * {
    all: revert
  }

  table {
    border-collapse: collapse;

    th,td {
      padding: 5px;
      margin: 0;
      border-left: 1px solid $grey-light;
      border-right: 1px solid $grey-light;
      border-bottom: 1px solid $grey-light;
    }
  }

  a {
    color: $link;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wiki-editor.textarea {
  font-family: monospace;
  min-height: 500px;
}

.wiki .card-header-title label {
  width: 100px;
}

.wiki pre{
  background-color: #f9f9f9;
  margin: 1.5em 10px;
  padding: 1em 10px .1em 10px;
}

.wiki blockquote{
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 1em 10px .1em 10px;
}



.panel-element-view{
  position:relative;
   & .button-icon-delete{
    position:absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
   }
}

#particle-backdrop{
  position:fixed;
  z-index:0;
}

.user-info{
  
  .notification{
    border-radius: 0;
    z-index:10;
  }
}

.error-404-container
{
  z-index:10;
}


pre.pre-debug-variable {
  white-space: pre-wrap;      
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap;     
  white-space: -o-pre-wrap;   
  word-wrap: break-word;      
 }