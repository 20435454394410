// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


// Set your brand colors
/*
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

$black: #111111;


// Update Bulma's global variables
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-background-color: $beige-lighter; 
$input-border-color: transparent;
$input-shadow: none;*/

// Import the rest of Bulma

$font-body: "Zilla Slab", serif;
$font-title: "Open Sans",sans-serif;


$family-sans-serif: "Nunito", sans-serif;
$family-sans-serif: $font-title;

$red: #EE3300;
//$primary: $red;
$primary: #006666;
$info: #006666;


@import "node_modules/bulma-checkbox/bulma-checkbox";
@import "node_modules/bulma-radio/bulma-radio";
@import "node_modules/bulma-switch-control/bulma-switch-control";
@import "node_modules/bulma-responsive-tables/bulma-responsive-tables";

@import "bulma";
@import "~@creativebulma/bulma-divider";

$border: $grey-light;
$divider-background-color: $grey-lighter;
$divider-thickness:  .1rem;
$divider-font-size:  $size-7;
$divider-color:  $grey-light;



@mixin global-shadow{
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1); 
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
}

$dark  : #415462;
$light : #a2afb9;


$nav-height: 64px;
$footer-height: 0px;
$sidebar-width: 220px;

$nav-height-mobile: 25px;


.is-microscopic{
  font-size: 10px;
}
